<template>
  <v-card>
    <v-card-title>
      그룹 사용자관리
      <SearchUsers :checked="assignUsersIdxes" @action="addGroupUsers" />
      <SearchAlias :checked="assignAliasesIdxes" @action="addGroupAlias" />
      <v-spacer />
      <v-btn icon @click="close">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <div class="d-flex">
      <div class="w-50">
        <v-card-subtitle>
          <v-text-field
            v-model="keyword"
            label="사용자 필터링"
            prepend-inner-icon="mdi-keyboard-outline"
            :append-icon="keyword ? 'mdi-close-circle' : null"
            @click:append="keyword = null"
            @keyup="filterKeyword"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-center">
                    아이디
                  </th>
                  <th class="text-center">
                    이름
                  </th>
                  <th class="text-center">
                    <v-btn icon @click="deleteGroupUser(null, 'all', true)">
                      <v-icon>
                        mdi-trash-can
                      </v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody v-if="users && users.length > 0">
                <tr v-for="user in users" :key="'group-users-' + user.idx">
                  <td class="text-center">
                    {{ user.users.id }}
                  </td>
                  <td class="text-center">
                    {{ user.users.username }}
                  </td>
                  <td class="text-center">
                    <v-btn icon @click="deleteGroupUser(user, 'individual', true)">
                      <v-icon>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3" class="text-center">
                    등록된 사용자가 존재하지 않습니다.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </div>
      <div class="w-50">
        <v-card-subtitle>
          <v-text-field
            v-model="aliasKeyword"
            label="추가사용자 필터링"
            prepend-inner-icon="mdi-keyboard-outline"
            :append-icon="aliasKeyword ? 'mdi-close-circle' : null"
            @click:append="aliasKeyword = null"
            @keyup="filterAliasKeyword"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-center">
                    아이디
                  </th>
                  <th class="text-center">
                    이름
                  </th>
                  <th class="text-center">
                    <v-btn icon @click="deleteGroupUser(null, 'all', false)">
                      <v-icon>
                        mdi-trash-can
                      </v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody v-if="aliases && aliases.length > 0">
                <tr v-for="alias in aliases" :key="'group-alias-' + alias.idx">
                  <td class="text-center">
                    {{ alias.alias ? alias.alias.id : '-' }}
                  </td>
                  <td class="text-center">
                    {{ alias.alias ? alias.alias.username : '-' }}
                  </td>
                  <td class="text-center">
                    <v-btn icon @click="deleteGroupUser(alias, 'individual', false)">
                      <v-icon>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3" class="text-center">
                    등록된 추가사용자가 존재하지 않습니다.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
  import SearchUsers from '../user/SearchUsers'
  import SearchAlias from '../user/SearchAlias'
  export default {
    name: 'ManageGroupUsers',
    components: { SearchUsers, SearchAlias },
    props: {
      group: Object,
    },
    data () {
      return {
        isShowSearchUser: false,
        isLoading: false,
        keyword: null,
        aliasKeyword: null,
        groupUsers: [],
        users: [],
        groupAliases: [],
        aliases: [],
      }
    },
    computed: {
      assignUsersIdxes () {
        const array = []
        for (const user of this.groupUsers) {
          array.push(user.users.idx)
        }
        return array
      },
      assignAliasesIdxes () {
        const array = []
        for (const alias of this.groupAliases) {
          array.push(alias.alias.idx)
        }
        return array
      },
    },
    beforeMount () {
      this.getGroupUsers()
    },
    methods: {
      async getGroupUsers () {
        const url = `/group/${this.group.idx}/users`
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.groupUsers = res.data.users
            this.groupAliases = res.data.aliases
          }
          this.users = this.groupUsers
          this.aliases = this.groupAliases
        })
      },
      filterKeyword () {
        if (this.keyword) {
          const filtered = []
          const keyword = this.keyword
          this.groupUsers.forEach(item => {
            if (item.users.id.indexOf(keyword) === 0 || item.users.username.indexOf(keyword) === 0) {
              filtered.push(item)
            }
          })
          this.users = filtered
        } else {
          this.users = this.groupUsers
        }
      },
      filterAliasKeyword () {
        if (this.aliasKeyword) {
          const filtered = []
          const keyword = this.aliasKeyword
          this.groupAliases.forEach(item => {
            if (item.alias.id.indexOf(keyword) === 0 || item.alias.username.indexOf(keyword) === 0) {
              filtered.push(item)
            }
          })
          this.aliases = filtered
        } else {
          this.aliases = this.groupAliases
        }
      },
      async addGroupUsers (idxes) {
        const url = `/manage/group/${this.group.idx}/user`
        await this.$axios.post(url, idxes).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.getGroupUsers()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async addGroupAlias (idxes) {
        const url = `/manage/group/${this.group.idx}/alias`
        await this.$axios.post(url, idxes).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.getGroupUsers()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async deleteGroupUser (user, type, isUser) {
        let msg
        if (type === 'all') {
          if (isUser) {
            msg = '모든 사용자를 그룹에서 제외하시겠습니까?'
          } else {
            msg = '모든 추가사용자를 그룹에서 제외하시겠습니까?'
          }
        } else {
          msg = '해당 사용자를 그룹에서 제외하시겠습니까?'
        }
        if (confirm(msg)) {
          const url = `/manage/group/${this.group.idx}/users`
          const params = {
            idx: user ? user.idx : null,
            type: type,
            isUser: isUser,
          }
          await this.$axios.delete(url, { params: params }).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              this.getGroupUsers()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      close () {
        this.$emit('refresh')
        // this.assignUsers = this.product.productAssigns
        this.keyword = null
      },
    },
  }
</script>
<style scoped>

</style>
