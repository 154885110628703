<template>
  <v-row>
    <v-col cols="12">
      <v-subheader class="px-0">
        <span>
          Manage New Products Page.
        </span>
      </v-subheader>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManageProductHeader',
  }
</script>

<style scoped>

</style>
