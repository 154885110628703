<template>
  <v-dialog
    v-model="isOpen"
    width="400"
    persistent
    max-width="100%"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        small
        class="mx-2"
        v-bind="attrs"
        v-on="on"
      >
        그룹
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-icon class="mr-2">
          mdi-account-group
        </v-icon>
        <h5>
          그룹 불러오기
        </h5>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        <v-text-field
          v-model="keyword"
          label="그룹 필터링"
          prepend-inner-icon="mdi-keyboard-outline"
          :append-icon="keyword ? 'mdi-close-circle' : null"
          @click:append="keyword = null"
          @keyup="filterKeyword"
        />
      </v-card-subtitle>
      <v-card-text>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-center">
                  그룹명
                </th>
                <th class="text-center">
                  그룹원
                </th>
                <th class="text-center">
                  <v-icon
                    @click="loadAllGroups"
                  >
                    mdi-cloud-download
                  </v-icon>
                </th>
              </tr>
            </thead>
            <tbody v-if="filteredGroups && filteredGroups.length > 0">
              <tr v-for="group in filteredGroups" :key="'assign-' + group.idx">
                <td class="text-center">
                  {{ group.groupName }}
                </td>
                <td class="text-center">
                  {{ group.userCount }}명
                </td>
                <td class="text-center">
                  <v-btn icon @click="loadGroup(group)">
                    <v-icon>
                      mdi-cloud-download-outline
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">
                  등록된 그룹이 존재하지 않습니다.
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'LoadGroup',
    props: {
      product: Object,
    },
    data () {
      return {
        isOpen: false,
        filteredGroups: [],
        groups: [],
        keyword: null,
      }
    },
    beforeMount () {
      this.getGroups()
    },
    methods: {
      async getGroups () {
        const url = '/group/groups'
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.groups = res.data
            if (this.keyword) {
              const filtered = []
              const keyword = this.keyword
              this.groups.forEach(item => {
                if (item.groupName.indexOf(keyword) === 0) {
                  filtered.push(item)
                }
              })
              this.filteredGroups = filtered
            } else {
              this.filteredGroups = this.groups
            }
          }
        })
      },
      filterKeyword () {
        if (this.keyword) {
          const filtered = []
          const keyword = this.keyword
          this.groups.forEach(item => {
            if (item.groupName.indexOf(keyword) === 0) {
              filtered.push(item)
            }
          })
          this.filteredGroups = filtered
        } else {
          this.filteredGroups = this.groups
        }
      },
      async loadGroup (group) {
        if (confirm(group.groupName + '의 사용자 및 추가사용자를 불러오시겠습니까?')) {
          const url = `/manage/product/${this.product.idx}/group/${group.idx}`
          await this.$axios.post(url).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('그룹 불러오기를 완료하였습니다.')
              this.$emit('refresh')
              // this.close()
            } else {
              alert(res.data.message || '오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      async loadAllGroups () {
        if (confirm('모든 그룹의 사용자 및 추가사용자를 불러오시겠습니까?')) {
          const url = `/manage/product/${this.product.idx}/group/all`
          await this.$axios.post(url).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('그룹 불러오기를 완료하였습니다.')
              this.$emit('refresh')
              // this.close()
            } else {
              alert(res.data.message || '오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      close () {
        this.isOpen = false
      },
    },
  }
</script>

<style scoped>

</style>
