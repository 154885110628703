<template>
  <v-dialog
    v-model="isOpen"
    width="400"
    persistent
    max-width="100%"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        small
        class="mx-2"
        v-bind="attrs"
        v-on="on"
      >
        추가사용자 검색
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-icon class="mr-2">
          mdi-account-search
        </v-icon>
        <h5>
          추가사용자 검색
        </h5>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="py-3">
        <v-select
          v-model="field"
          :items="fields"
          label="조건"
          hide-details
        />
        <v-text-field
          v-model="keyword"
          label="추가사용자 검색"
          append-icon="mdi-magnify"
          hide-details
          @click:append="searchUsers(null)"
          @keydown="enterSearch"
        />
      </v-card-subtitle>
      <v-card-text>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-center">
                  <v-checkbox
                    ref="checkAll"
                    v-model="checkAll"
                  />
                </th>
                <th class="text-center">
                  아이디
                </th>
                <th class="text-center">
                  이름
                </th>
              </tr>
            </thead>
            <tbody v-if="pagination && pagination.list && pagination.list.length > 0">
              <tr v-for="users in pagination.list" :key="'search-users-' + users.idx">
                <td>
                  <v-checkbox
                    v-model="selected"
                    :value="users.idx"
                  />
                </td>
                <td class="text-center">
                  {{ users.id }}
                </td>
                <td class="text-center">
                  {{ users.username }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center text-body-2">
                  검색된 사용자가 존재하지 않습니다.
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <LivePagination
        :pagination="pagination"
        :show-num="7"
        :query="$route.query"
        @pagination="searchUsers"
        @last="getLastPage"
        @first="getFirstPage"
      />
      <v-card-actions>
        <v-btn
          width="100%"
          @click="action"
        >
          사용자 선택 {{ selected.length }}명
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import LivePagination from '../core/LivePagination'
  export default {
    name: 'SearchAlias',
    components: { LivePagination },
    props: {
      checked: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    data () {
      return {
        isOpen: false,
        keyword: null,
        field: 'username',
        fields: [
          {
            text: '이름',
            value: 'username',
          },
          {
            text: '아이디',
            value: 'id',
          },
        ],
        selected: [],
        pagination: {
          list: [],
          pageNum: 1,
        },
        pageDataSize: 5,
        idxes: [],
      }
    },
    computed: {
      checkAll: {
        get () {
          return this.pagination ? (this.selected ? (this.selected.length === this.pagination.list.length) : false) : false
        },
        set (value) {
          if (value) {
            this.selected = this.idxes
          } else {
            this.selected = []
          }
        },
      },
    },
    watch: {
      checked () {
        if (this.checked) {
          this.selected = this.checked
        }
      },
      isOpen () {
        if (this.isOpen) {
          this.searchUsers()
          if (this.checked) {
            this.selected = this.checked
          }
        }
      },
    },
    beforeMount () {
      this.searchUsers()
    },
    methods: {
      async searchUsers (pageNum) {
        const url = `/manage/user/aliases/${pageNum || this.pagination.pageNum}`
        if (this.keyword && this.keyword.length > 0 && this.keyword.length < 2) {
          alert('검색어는 두글자 이상 입력바랍니다.')
          return
        }
        const params = {
          pageDataSize: this.pageDataSize,
          keyword: this.keyword,
          field: this.field,
        }
        await this.$axios.get(url, { params: params }).then(res => {
          this.pagination = res.data
          this.getIdxes()
        })
      },
      async getIdxes () {
        const url = '/manage/user/aliases/index'
        const params = {
          keyword: this.keyword,
          field: this.field,
        }
        await this.$axios.get(url, { params: params }).then(res => {
          this.idxes = res.data
        })
      },
      getLastPage () {
        const url = `/manage/user/aliases/${this.pagination.maxPageNum}`
        const params = {
          pageDataSize: this.pageDataSize,
          keyword: this.keyword,
          field: this.field,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getFirstPage () {
        const url = `/manage/user/aliases/1`
        const params = {
          pageDataSize: this.pageDataSize,
          keyword: this.keyword,
          field: this.field,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.searchUsers(1)
        }
      },
      action () {
        this.$emit('action', this.selected)
        this.close()
      },
      close () {
        this.isOpen = false
        this.selected = []
        this.keyword = null
        this.field = 'username'
        this.pagination = {
          list: [],
          pageNum: 1,
        }
      },
    },
  }
</script>

<style scoped>

</style>
