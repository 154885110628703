<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-subheader>
        신규상품 관리
      </v-subheader>
      <v-sheet class="px-3 pb-0 d-flex" />
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th>
                <v-checkbox v-model="checkAll" />
              </th>
              <th
                v-for="(col, i) in cols"
                :id="'product-' + i"
                :key="i"
                class="text-subtitle-1 text-center"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody v-if="pagination && pagination.list && pagination.list.length > 0">
            <tr v-for="(item, i) in pagination.list" :key="i">
              <td class="text-center">
                <v-checkbox v-model="selected" :value="item.idx" />
              </td>
              <td class="text-center text-truncate">
                <span class="text-truncate">
                  {{ item.company ? item.company.companyName : '정보없음' }}
                </span>
              </td>
              <td class="text-center text-truncate">
                <v-dialog
                  v-model="item.showEditor"
                  persistent
                  :fullscreen="mobile"
                  :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                  max-width="100%"
                  width="1300px"
                >
                  <template #activator="{ attrs, on }">
                    <span
                      class="text-truncate"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-tooltip bottom>
                        <template #activator="{ subAttrs, subOn }">
                          <template v-if="item.month">
                            [한달]
                          </template>
                          <span v-bind="subAttrs" v-on="subOn">
                            {{ item.productName }}
                          </span>
                        </template>
                        <v-img
                          :src="item.productPhoto1"
                          width="200px"
                          :aspect-ratio="16/9"
                        />
                      </v-tooltip>
                    </span>
                  </template>
                  <ManageProductEditor
                    :org-product="item"
                    :companies="companies"
                    @close-dialog="closeEditor"
                  />
                </v-dialog>
              </td>
              <td class="text-center text-truncate">
                <v-dialog
                  v-model="item.showRegister"
                  persistent
                  max-width="1500"
                >
                  <template #activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="mr-2">
                        mdi-account-group
                      </v-icon>
                      {{ item.productAssigns.length }}명
                    </v-btn>
                  </template>
                  <ProductAssignRegister v-if="item.showRegister" ref="productAssignRegister" :product-idx="item.idx" @refresh="refreshPagination" @close-dialog="closeRegister(item)" />
                </v-dialog>
              </td>
              <td class="text-center text-truncate">
                <span v-if="item.totalStock != null" class="text-truncate">
                  <span v-if="item.totalStock > 0">
                    {{ item.totalStock }}개
                  </span>
                  <span v-else class="error--text">
                    품절
                  </span>
                </span>
                <span v-else class="text-truncate">
                  제한없음
                </span>
              </td>
              <td class="text-center text-truncate">
                <v-tooltip v-if="item.valid === 'wait'" bottom>
                  <template #activator="{ attrs, on }">
                    <span class="warning--text" v-bind="attrs" v-on="on">
                      신청대기
                    </span>
                  </template>
                  <span>
                    {{ parseDate(item.startDate) }} - {{ parseDate(item.endDate) }}
                  </span>
                </v-tooltip>
                <v-tooltip v-else-if="item.valid === 'end'" bottom>
                  <template #activator="{ attrs, on }">
                    <span class="error--text" v-bind="attrs" v-on="on">
                      신청종료
                    </span>
                  </template>
                  <span>
                    {{ parseDate(item.startDate) }} - {{ parseDate(item.endDate) }}
                  </span>
                </v-tooltip>
                <v-tooltip v-else bottom>
                  <template #activator="{ attrs, on }">
                    <span class="success--text" v-bind="attrs" v-on="on">
                      신청가능
                    </span>
                  </template>
                  <span>
                    {{ parseDate(item.startDate) }} - {{ parseDate(item.endDate) }}
                  </span>
                </v-tooltip>
              </td>
              <td class="text-center">
                <span :class="{ 'error--text' : item.status === 'reject', 'success--text' : item.status === 'confirm' }">
                  {{ item.strStatus }}
                </span>
              </td>
              <td class="text-center">
                <v-btn
                  icon
                  @click="exportToExcel(item)"
                >
                  <v-icon>
                    mdi-microsoft-excel
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-sheet width="100%" class="pb-15 pb-md-3 mt-3">
        <v-row>
          <v-col cols="12" md="3">
            <v-btn class="ml-2" @click="updateProductStatus('confirm')">
              진행
            </v-btn>
            <v-btn class="ml-1" @click="updateProductStatus('reject')">
              종료
            </v-btn>
            <v-btn class="ml-1" @click="syncStart">
              동기화
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <Pagination ref="pagination" :pagination="pagination" base-url="/manage/product/" :query="$route.query" />
          </v-col>
          <v-col cols="12" md="3" class="d-flex justify-end pt-0 pt-md-3">
            <v-dialog
              v-model="showGroupDialog"
              persistent
              :fullscreen="mobile"
              :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
              max-width="100%"
              width="800"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  그룹
                </v-btn>
              </template>
              <ManageGroup v-if="showGroupDialog" @close-dialog="showGroupDialog = false" />
            </v-dialog>
            <v-dialog
              v-model="showOptionDialog"
              persistent
              :fullscreen="mobile"
              :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
              max-width="100%"
              width="400"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  옵션
                </v-btn>
              </template>
              <OptionType @close-dialog="closeOption" />
            </v-dialog>
            <v-dialog
              v-model="showRegister"
              persistent
              :fullscreen="mobile"
              :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
              max-width="100%"
              width="1300px"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  class="ml-1 mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  등록
                </v-btn>
              </template>
              <ManageProductEditor :companies="companies" @close-dialog="closeEditor" />
            </v-dialog>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
  import ProductAssignRegister from '../../../components/product/ProductAssignRegister'
  import Pagination from '@/components/core/Pagination'
  import ManageProductEditor from '@/views/manage/product/Editor'
  import OptionType from '@/views/manage/product/option/OptionType'
  import ManageGroup from '@/components/group/ManageGroup'
  export default {
    name: 'ManageProductContent',
    components: { ManageGroup, OptionType, ManageProductEditor, Pagination, ProductAssignRegister },
    props: {
      pagination: {},
      companies: {},
    },
    data () {
      return {
        cols: ['업체', '키워드', '배정인원', '재고', '신청', '진행', '출력'],
        isLoading: false,
        selected: [],
        showRegister: false,
        showOptionDialog: false,
        showGroupDialog: false,
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
      checkAll: {
        get () {
          return this.pagination && this.pagination.list ? (this.selected ? (this.selected.length === this.pagination.list.length) : false) : false
        },
        set (value) {
          const selected = []
          if (value) {
            this.pagination.list.forEach(product => {
              selected.push(product.idx)
            })
            this.selected = selected
          } else {
            this.selected = []
          }
        },
      },
    },
    methods: {
      async syncStart () {
        if (confirm('동기화를 시작 하시겠습니까?')) {
          const data = {
            products: this.selected,
          }
          await this.$axios.post('/manage/site/sync', data).then(res => {
            if (res.data.status === 'ERROR') {
              alert(res.data.message)
            }
          })
        }
      },
      async saveCompany (company) {
        if (confirm('업체를 수정하시겠습니까?')) {
          const url = '/manage/company'
          await this.$axios.patch(url, company).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('처리가 완료되었습니다.')
              this.getCompanies()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      parseDate (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY.MM.DD')
        } else {
          return date
        }
      },
      parseDateTime (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY.MM.DD HH:mm')
        } else {
          return date
        }
      },
      closeRegister (product) {
        product.showRegister = false
        this.refreshPagination()
      },
      closeEditor (product) {
        if (product) {
          product.showEditor = false
        } else {
          this.showRegister = false
        }
        this.refreshPagination()
      },
      closeOption () {
        this.showOptionDialog = false
      },
      refreshPagination () {
        this.$emit('refresh')
      },
      async updateProductStatus (status) {
        const url = '/manage/product/status'
        if (this.selected.length === 0) {
          alert('상품을 선택해주세요.')
          return
        }
        await this.$axios.patch(url, this.selected, { params: { status: status } }).then(res => {
          if (res.data.status === 'SUCCESS') {
            this.refreshPagination()
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async exportToExcel (product) {
        const url = `/manage/product/${product.idx}/download`
        await this.$axios.get(url, {
          params: this.$route.query,
          responseType: 'blob',
        }).then(res => {
          if (res && res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
            const today = new Date()
            const fileName = product.productName + '_신청_' + today.getFullYear() + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + (today.getDay() > 9 ? today.getDay() : '0' + today.getDay()) + '.xlsx'
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          }
        })
      },
    },
  }
</script>

<style scoped>

</style>
