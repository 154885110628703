<template>
  <v-card>
    <v-card-title>
      그룹 관리
      <v-spacer />
      <v-btn icon @click="close">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="newGroup.groupName"
        label="그룹명"
        placeholder="그룹명을 입력해주세요"
        hide-details
        append-icon="mdi-plus"
        @click:append="registerGroup"
        @keyup="enterRegister"
      />
    </v-card-subtitle>
    <v-card-subtitle>
      <v-text-field
        v-model="keyword"
        label="그룹 필터링"
        prepend-inner-icon="mdi-keyboard-outline"
        :append-icon="keyword ? 'mdi-close-circle' : null"
        @click:append="keyword = null"
        @keyup="filterKeyword"
      />
    </v-card-subtitle>
    <v-card-text>
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="text-center">
                그룹명
              </th>
              <th class="text-center">
                그룹원
              </th>
              <th class="text-center">
                <v-icon>
                  mdi-trash-can
                </v-icon>
              </th>
            </tr>
          </thead>
          <tbody v-if="filteredGroups && filteredGroups.length > 0">
            <tr v-for="group in filteredGroups" :key="'assign-' + group.idx">
              <td class="text-center">
                {{ group.groupName }}
              </td>
              <td class="text-center">
                <v-dialog
                  v-model="group.showUsers"
                  persistent
                  max-width="600"
                >
                  <template #activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="mr-2">
                        mdi-account-group
                      </v-icon>
                      {{ group.userCount }}명
                    </v-btn>
                  </template>
                  <ManageGroupUsers v-if="group.showUsers" ref="productAssignRegister" :group="group" @refresh="getGroups" @close-dialog="group.showUsers = false" />
                </v-dialog>
              </td>
              <td class="text-center">
                <v-btn icon @click="deleteGroup(group)">
                  <v-icon>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3" class="text-center">
                등록된 그룹이 존재하지 않습니다.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import ManageGroupUsers from '@/components/group/ManageGroupUsers'
  export default {
    name: 'ManageGroup',
    components: { ManageGroupUsers },
    props: {
      productIdx: Number,
    },
    data () {
      return {
        isShowSearchUser: false,
        isLoading: false,
        keyword: null,
        aliasKeyword: null,
        users: [],
        assignUsers: [],
        assignAliases: [],
        groups: [],
        filteredGroups: [],
        newGroup: {
          groupName: null,
        },
      }
    },
    computed: {
    },
    beforeMount () {
      this.getGroups()
    },
    methods: {
      async getGroups () {
        const url = '/group/groups'
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.groups = res.data
            if (this.keyword) {
              const filtered = []
              const keyword = this.keyword
              this.groups.forEach(item => {
                if (item.groupName.indexOf(keyword) === 0) {
                  filtered.push(item)
                }
              })
              this.filteredGroups = filtered
            } else {
              this.filteredGroups = this.groups
            }
          }
        })
      },
      enterRegister (e) {
        if (e.keyCode === 13) {
          this.registerGroup()
        }
      },
      async registerGroup () {
        if (!this.newGroup || !this.newGroup.groupName) {
          alert('그룹명을 입력해주세요.')
          return
        }
        const url = `/manage/group`
        await this.$axios.post(url, this.newGroup).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.keyword = null
            this.getGroups()
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async deleteGroup (group) {
        if (confirm('\'' + group.groupName + '\' 그룹을 삭제하시겠습니까?')) {
          const url = `/manage/group/${group.idx}`
          await this.$axios.delete(url).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              this.getGroups()
            } else {
              alert(res.data.message || '오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      filterKeyword () {
        if (this.keyword) {
          const filtered = []
          const keyword = this.keyword
          this.groups.forEach(item => {
            if (item.groupName.indexOf(keyword) === 0) {
              filtered.push(item)
            }
          })
          this.filteredGroups = filtered
        } else {
          this.filteredGroups = this.groups
        }
      },
      async deleteGroups (groups) {
        if (confirm('\'' + groups.groupName + '\' 그룹을 삭제하시겠습니까?')) {
          const url = '/group'
          await this.$axios.delete(url, { data: groups }).then(res => {
            if (res.data && res.data.status === 'SUCCESS') {
              this.getGroups()
            }
          })
        }
      },
      close () {
        this.$emit('close-dialog')
        this.filteredGroups = this.groups
        this.keyword = null
      },
    },
  }
</script>
<style scoped>

</style>
