<template>
  <v-card class="pt-10 pt-md-3">
    <v-card-title>
      옵션 타입
      <v-spacer />
      <v-btn
        icon
        @click="close"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="optionType.optionTypeName"
        label="Name."
        placeholder="옵션명을 입력해주세요."
        append-icon="mdi-plus"
        @click:append="registerOptionType"
      />
    </v-card-subtitle>
    <v-card-text>
      <template v-for="type in optionTypes">
        <v-text-field
          :key="'option-type-' + type.idx"
          v-model="type.optionTypeName"
          dense
          filled
          append-icon="mdi-content-save-outline"
          append-outer-icon="mdi-trash-can-outline"
          @click:append="saveOptionType(type)"
          @click:append-outer="deleteOptionType(type)"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'OptionType',
    data () {
      return {
        optionType: {
          optionTypeName: null,
        },
        optionTypes: [],
      }
    },
    beforeMount () {
      this.getOptionTypes()
    },
    methods: {
      async getOptionTypes () {
        await this.$axios.get('/manage/product/option/types').then(res => {
          if (res && res.data) {
            this.optionTypes = res.data
          }
        })
      },
      async saveOptionType (optionType) {
        await this.$axios.patch('/manage/product/option/type', optionType).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('수정되었습니다.')
            this.getOptionTypes()
          }
        })
      },
      async deleteOptionType (optionType) {
        if (confirm('해당 옵션에 대한 모든 상품정보가 사라집니다.\n삭제하시겠습니까?')) {
          await this.$axios.delete('/manage/product/option/type', { data: optionType }).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('삭제되었습니다.')
              this.getOptionTypes()
            }
          })
        }
      },
      async registerOptionType () {
        if (!this.optionType.optionTypeName) {
          alert('옵션 타입명을 입력해주세요.')
          return
        }
        await this.$axios.post('/manage/product/option/type', this.optionType).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.optionType.optionTypeName = null
            this.getOptionTypes()
          }
        })
      },
      close () {
        this.optionType.optionTypeName = null
        this.$emit('close-dialog')
      },
    },
  }
</script>

<style scoped>

</style>
