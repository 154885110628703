import { render, staticRenderFns } from "./ManageGroup.vue?vue&type=template&id=d471ce3c&scoped=true&"
import script from "./ManageGroup.vue?vue&type=script&lang=js&"
export * from "./ManageGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d471ce3c",
  null
  
)

export default component.exports