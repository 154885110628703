<template>
  <v-card>
    <v-card-title>
      신규상품 권한등록
      <SearchUsers :checked="assignUsersIdxes" @action="addProductAssignUsers" />
      <SearchAlias :checked="assignAliasesIdxes" @action="addProductAssignAlias" />
      <LoadGroup :product="product" @refresh="getProduct" />
      <v-btn small @click="copyRegistration">신청목록 복사</v-btn>
      <v-spacer />
      <v-btn icon @click="close">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title v-if="registeredUsers">
      <v-textarea
        v-model="registeredUsers"
        :label="'신청된 사용자 목록 (' + registeredUsersCount + ')'"
        rows="4"
        readonly
        no-resize
        hide-details
      />
    </v-card-title>
    <v-row>
      <v-col cols="12" md="6">
        <v-card-subtitle>
          <v-text-field
            v-model="keyword"
            label="사용자 필터링"
            prepend-inner-icon="mdi-keyboard-outline"
            :append-icon="keyword ? 'mdi-close-circle' : null"
            @click:append="keyword = null"
            @keyup="filterKeyword"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-row v-if="product && product.userGroups">
            <v-col v-for="group in product.userGroups" :key="`user-group-${group.idx}`" cols="6" md="6" class="d-flex">
              <v-card outlined rounded width="100%">
                <v-card-text>
                  <p class="mx-1" :style="{ lineHeight: '28px' }">
                    {{ group.groupName }}
                  </p>
                  <div class="w-100 d-flex" :class="{ 'text-center': $isMobile }">
                    <v-btn
                      color="success"
                      small
                      class="mx-1"
                      @click="updateGroupAssignPermit(group, 'users', true)"
                    >
                      신청
                    </v-btn>
                    <v-btn
                      color="error"
                      small
                      class="mx-1"
                      @click="updateGroupAssignPermit(group, 'users', false)"
                    >
                      불가
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-center">
                    그룹
                  </th>
                  <th class="text-center">
                    아이디
                  </th>
                  <th class="text-center">
                    이름
                  </th>
                  <th class="text-center">
                    <v-switch
                      v-model="userPermit"
                      color="success"
                      hide-spin-buttons
                      label="권한"
                      @change="updateGroupAssignPermit({ idx: 0 }, 'users', userPermit)"
                    />
                  </th>
                  <th class="text-center">
                    <v-btn icon @click="deleteProductAssignUser(null, 'all', true)">
                      <v-icon>
                        mdi-trash-can
                      </v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody v-if="assignUsers && assignUsers.length > 0">
                <tr v-for="assign in assignUsers" :key="'assign-' + product.idx + '-' + assign.idx">
                  <td class="text-center text-truncate">
                    {{ assign.groups ? assign.groups.groupName : '' }}
                  </td>
                  <td class="text-center text-truncate">
                    {{ assign.users.id }}
                  </td>
                  <td class="text-center text-truncate">
                    {{ assign.users.username }}
                  </td>
                  <td class="text-center text-truncate">
                    <v-switch
                      v-model="assign.permit"
                      color="success"
                      hide-spin-buttons
                      :label="assign.permit ? '신청' : '불가'"
                      @change="updateSingleAssignPermit(assign)"
                    />
                  </td>
                  <td class="text-center text-truncate">
                    <v-btn icon @click="deleteProductAssignUser(assign, 'individual', true)">
                      <v-icon>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    등록된 사용자가 존재하지 않습니다.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-col>
      <v-col cols="12" md="6">
        <v-card-subtitle>
          <v-text-field
            v-model="aliasKeyword"
            label="추가사용자 필터링"
            prepend-inner-icon="mdi-keyboard-outline"
            :append-icon="aliasKeyword ? 'mdi-close-circle' : null"
            @click:append="aliasKeyword = null"
            @keyup="filterAliasKeyword"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-row v-if="product && product.aliasGroups">
            <v-col v-for="group in product.aliasGroups" :key="`user-group-${group.idx}`" cols="6" md="6" class="d-flex">
              <v-card outlined rounded width="100%">
                <v-card-text>
                  <p class="mx-1" :style="{ lineHeight: '28px' }">
                    {{ group.groupName }}
                  </p>
                  <div class="w-100 d-flex" :class="{ 'text-center': $isMobile }">
                    <v-btn
                      color="success"
                      small
                      class="mx-1"
                      @click="updateGroupAssignPermit(group, 'alias', true)"
                    >
                      신청
                    </v-btn>
                    <v-btn
                      color="error"
                      small
                      class="mx-1"
                      @click="updateGroupAssignPermit(group, 'alias', false)"
                    >
                      불가
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-center">
                    그룹
                  </th>
                  <th class="text-center">
                    아이디
                  </th>
                  <th class="text-center">
                    이름
                  </th>
                  <th class="text-center">
                    <v-switch
                      v-model="aliasPermit"
                      color="success"
                      hide-spin-buttons
                      label="권한"
                      @change="updateGroupAssignPermit({ idx: 0 }, 'alias', aliasPermit)"
                    />
                  </th>
                  <th class="text-center">
                    <v-btn icon @click="deleteProductAssignUser(null, 'all', false)">
                      <v-icon>
                        mdi-trash-can
                      </v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody v-if="assignAliases && assignAliases.length > 0">
                <tr v-for="assign in assignAliases" :key="'assign-' + product.idx + '-' + assign.idx">
                  <td class="text-center text-truncate">
                    {{ assign.groups ? assign.groups.groupName : '' }}
                  </td>
                  <td class="text-center text-truncate">
                    {{ assign.alias ? assign.alias.id : '-' }}
                  </td>
                  <td class="text-center text-truncate">
                    {{ assign.alias ? assign.alias.username : '-' }}
                  </td>
                  <td class="text-center text-truncate">
                    <v-switch
                      v-model="assign.permit"
                      color="success"
                      @change="updateSingleAssignPermit(assign)"
                    />
                  </td>
                  <td class="text-center text-truncate">
                    <v-btn icon @click="deleteProductAssignUser(assign, 'individual', false)">
                      <v-icon>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    등록된 추가사용자가 존재하지 않습니다.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import SearchUsers from '../user/SearchUsers'
  import SearchAlias from '../user/SearchAlias'
  import LoadGroup from '@/components/group/LoadGroup'
  export default {
    name: 'ProductAssignRegister',
    components: { LoadGroup, SearchUsers, SearchAlias },
    props: {
      productIdx: Number,
    },
    data () {
      return {
        isShowSearchUser: false,
        isLoading: false,
        keyword: null,
        aliasKeyword: null,
        users: [],
        assignUsers: [],
        assignAliases: [],
        product: {},
        userPermit: true,
        aliasPermit: true,
        registeredUsers: '',
      }
    },
    computed: {
      assigns () {
        return this.assignUsers
      },
      assignUsersIdxes () {
        const array = []
        for (const user of this.assignUsers) {
          array.push(user.users.idx)
        }
        return array
      },
      assignAliasesIdxes () {
        const array = []
        for (const alias of this.assignAliases) {
          array.push(alias.alias.idx)
        }
        return array
      },
      registeredUsersCount () {
        const count = this.registeredUsers ? this.registeredUsers.split(',').length : 0
        const allUsers = this.assignUsers.length + this.assignAliases.length
        return count + '/' + allUsers
      },
    },
    beforeMount () {
      this.getProduct()
    },
    methods: {
      async getProduct () {
        const url = `/product/${this.productIdx}`
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.product = res.data
            if (this.keyword) {
              const filtered = []
              const keyword = this.keyword
              this.product.productAssigns.forEach(item => {
                if (item.users.id.indexOf(keyword) === 0 || item.users.username.indexOf(keyword) === 0) {
                  filtered.push(item)
                }
              })
              this.assignUsers = filtered
            } else {
              this.assignUsers = this.product.userAssigns
              this.assignAliases = this.product.aliasAssigns
            }
          }
        })
      },
      filterKeyword () {
        if (this.keyword) {
          const filtered = []
          const keyword = this.keyword
          this.product.userAssigns.forEach(item => {
            if (item.users.id.indexOf(keyword) === 0 || item.users.username.indexOf(keyword) === 0) {
              filtered.push(item)
            }
          })
          this.assignUsers = filtered
        } else {
          this.assignUsers = this.product.userAssigns
        }
      },
      filterAliasKeyword () {
        if (this.aliasKeyword) {
          const filtered = []
          const keyword = this.aliasKeyword
          this.product.aliasAssigns.forEach(item => {
            if (item.alias.id.indexOf(keyword) === 0 || item.alias.username.indexOf(keyword) === 0) {
              filtered.push(item)
            }
          })
          this.assignAliases = filtered
        } else {
          this.assignAliases = this.product.aliasAssigns
        }
      },
      async addProductAssignUsers (idxes) {
        const url = `/manage/product/${this.product.idx}/assign`
        await this.$axios.post(url, idxes).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.getProduct()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async addProductAssignAlias (idxes) {
        const url = `/manage/product/${this.product.idx}/assign/alias`
        await this.$axios.post(url, idxes).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.getProduct()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async deleteProductAssignUser (assign, type, isUser) {
        let msg
        if (type === 'all') {
          msg = '신규 상품에 대한 권한을 모두 제거하시겠습니까?'
        } else {
          msg = '해당 사용자에 대한 신규 상품에 대한 권한을 제거하시겠습니까?'
        }
        if (confirm(msg)) {
          const url = '/manage/product/assign'
          const params = {
            idx: assign ? assign.idx : null,
            product: this.product.idx,
            type: type,
            isUser: isUser,
          }
          await this.$axios.delete(url, { params: params }).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              this.getProduct()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      async updateSingleAssignPermit (assign) {
        const url = `/manage/product/assign/permit`
        await this.$axios.patch(url, assign).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            // ignore
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async updateGroupAssignPermit (groups, userType, permit) {
        if (!permit) {
          permit = false
          groups.on = true
        }
        const url = `/manage/product/assign/permit/groups/${groups.idx}`
        await this.$axios.patch(url, this.product, { params: { userType: userType, permit: permit } }).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.getProduct()
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async copyRegistration () {
        const url = '/manage/product/registration/users/list?productIdx=' + this.productIdx
        await this.$axios.get(url).then(res => {
          this.registeredUsers = res.data.data
        })
      },
      close () {
        this.$emit('refresh')
        this.assignUsers = this.product.productAssigns
        this.keyword = null
        this.beforeGroup = null
        this.aliasBeforeGroup = null
      },
    },
  }
</script>

<style scoped>

</style>
