<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageProductHeader />
      <ManageProductSearch :companies="companies" @search="getPagination" />
      <ManageProductContent ref="productContent" :companies="companies" :pagination="pagination" @refresh="getPagination" />
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageProductHeader from './Header'
  import ManageProductContent from './Content'
  import ManageProductSearch from '@/views/manage/product/Search'
  export default {
    name: 'Product',
    components: { ManageProductSearch, ManageProductContent, ManageProductHeader },
    data () {
      return {
        searchField: this.$route.query.searchField ? this.$route.query.searchField : null,
        company: {
          idx: this.$route.query.company ? parseInt(this.$route.query.company | String) : null,
        },
        pagination: {
          list: [],
          pageNum: this.pageNum ? this.pageNum : 1,
        },
        pageDataSize: 10,
        companies: [],
      }
    },
    computed: {
      status () {
        return this.$route.query.status ? this.$route.query.status : ''
      },
      pageNum () {
        return this.$route.params.pageNum
      },
      keyword () {
        return this.$route.query.keyword ? this.$route.query.keyword : ''
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
    },
    beforeMount () {
      this.getPagination()
      this.getCompanies()
    },
    methods: {
      async getPagination (payload) {
        const url = '/product/products'
        const data = {
          keyword: this.keyword ? this.keyword : '',
          searchField: this.searchField ? this.searchField : '',
          company: this.company.idx ? this.company.idx : '',
          status: this.status ? this.status : '',
        }
        const params = payload || data
        params.pageNum = this.pageNum
        params.pageDataSize = this.pageDataSize
        await this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
        if (this.$refs.productContent) {
          this.$refs.productContent.selected = []
        }
      },
      async getCompanies () {
        const url = '/company/company/items'
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.companies = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style scoped>

</style>
